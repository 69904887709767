import { forwardRef } from "react";
import { MdClose } from "react-icons/md";
import styles from "./ServiceModal.module.css";
import { useColorTheme } from "../../../../ColorThemeProvider";

const ServiceModal = forwardRef(function ServiceModal(
  { onClose, service },
  ref
) {
  const { themeColor } = useColorTheme();

  return (
    <dialog ref={ref} className={styles.serviceModal}>
      <div className={styles.serviceModalContainer}>
        <div onClick={onClose} className={styles.close}>
          <button className={styles.closeBtn}>
            <MdClose />
          </button>
        </div>
        <img src={service.image_url || ""} alt="" />
        <div className={styles.content}>
          <div style={{ color: themeColor }} className={styles.title}>
            {service.title || ""}
          </div>
          <div className={styles.desc}>{service.description || ""}</div>
          <div className={styles.benefits}>
            <span>Benefits</span>
            {service.benefits || ""}
          </div>
        </div>
      </div>
    </dialog>
  );
});

export default ServiceModal;
