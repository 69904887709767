import Logo from "../../../../assets/enquiry-form/logo.svg";
import Image from "../../../../assets/enquiry-form/yogaImage.png";
import styles from "./AppDetails.module.css";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { IoLogoWhatsapp } from "react-icons/io";

import getLandingPageData from "../../../../services/helper/get_landing_page_data";

export default function AppDetails() {
  const landingPageData = getLandingPageData(window.pageData);
  const { salesPerson, business } = landingPageData;
  return (
    <div className={styles.appDetails}>
      <div className={styles.header}>
        <div>
          <img src={Logo} />
        </div>
        <div>
          <h3>Bliss Yoga</h3>
        </div>
      </div>
      <div className={styles.image}>
        <img src={Image} />
      </div>
      <div className={styles.contactSales}>
        <div className={styles.title}>Contact Sales</div>
        {salesPerson.mobileNumber && (
          <div className={styles.salesDetails}>
            <FaPhoneAlt />{" "}
            <span className={styles.details}>{salesPerson.mobileNumber}</span>
          </div>
        )}

        {salesPerson.email && (
          <div className={styles.salesDetails}>
            <IoMdMail />{" "}
            <span className={styles.details}>{salesPerson.email}</span>
          </div>
        )}

        {salesPerson.whatsappNumber && (
          <div className={styles.salesDetails}>
            <IoLogoWhatsapp />{" "}
            <span className={styles.details}>{salesPerson.whatsappNumber}</span>
          </div>
        )}
      </div>
    </div>
  );
}
