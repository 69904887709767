import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
const pageType = window.pageType;

function Main() {
  return (
    <BrowserRouter>
      <GoogleOAuthProvider clientId="751134488799-ejsm5bi8nb6tn78oosf1c7d9d4s1ufb0.apps.googleusercontent.com">
        <App pageType={pageType} />
      </GoogleOAuthProvider>
    </BrowserRouter>
  );
}

root.render(<Main />);
