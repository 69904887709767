import axios from "axios";
import { BASE_URL } from "../../../shared/constants";

export async function getFormByFormId(onSuccess, onFailure, formId) {
  try {
    const response = await axios.get(
      `${BASE_URL}/form/${formId}`,
      {},
      {
        "Content-Type": "application/json",
      }
    );

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    if (onFailure) {
      const errorMessage = error.response ? error.response.data : error.message;
      onFailure(errorMessage);
    }
  }
}
