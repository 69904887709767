import { forwardRef, useRef } from "react";
import styles from "./ServiceRequest.module.css";
import ServiceRequestModal from "../modals/Service-Request-Modal/ServiceRequestModal";
import { useColorTheme } from "../../ColorThemeProvider";

function ServiceRequest({ formId }) {
  const serviceRequestModalRef = useRef(null);

  const { themeColor } = useColorTheme();
  return (
    <div className={styles.serviceRequest}>
      <span className={styles.title}>Do you want us to call you back ?</span>
      <span className={styles.desc}>
        Please fix a day and time when we can call you back.
      </span>

      <button
        className={styles.button}
        style={{ backgroundColor: themeColor }}
        onClick={() =>
          serviceRequestModalRef.current &&
          serviceRequestModalRef.current.showModal()
        }
      >
        Set up a call
      </button>
      <ServiceRequestModal formId={formId} ref={serviceRequestModalRef} />
    </div>
  );
}

export default ServiceRequest;
