import styles from "./QueryInput.module.css";
import { useColorTheme } from "../../../ColorThemeProvider";
import SubmissionConfirmationPopup from "../../../../../member-dashboard/components/layouts/submission-confirmation-popup/SubmissionConfirmationPopup";
import { postFormResponses } from "../../../../services/network/post-form-response-api";
import { useRef, useEffect, useState } from "react";
import { getFormByFormId } from "../../../../services/network/get-form-by-formId";
import { Snackbar, Alert } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail, IoLogoWhatsapp } from "react-icons/io";

import getLandingPageData from "../../../../services/helper/get_landing_page_data";

export default function QueryInput({ formId }) {
  const landingPageData = getLandingPageData(window.pageData);
  const { salesPerson, business } = landingPageData;
  const submisssionConfirmationRef = useRef();
  const { themeColor } = useColorTheme();
  const [formData, setFormData] = useState();
  const [countryCode, setCountryCode] = useState("+91");
  const [submissionError, setSubmissionError] = useState(false);
  const [responses, setResponses] = useState([]);
  const [submissionConfirmationData, setSubmissionConfirmationData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
  });

  const formRef = useRef();

  useEffect(() => {
    getFormByFormId(
      (response) => {
        setFormData(response);
      },
      (error) => {
        console.error(error);
      },
      formId && formId
    );
  }, [formId]);

  const leadEnquiryFormData = formData && formData;

  const handleCountryCodeChange = (event) => {
    setCountryCode(event.target.value);
  };

  const choiceQuestion =
    leadEnquiryFormData &&
    leadEnquiryFormData.questions.find(
      (question) => question.type === "CHOICE"
    );

  const choiceQuestionId = choiceQuestion && choiceQuestion._id;

  function responseForAllQuestions() {
    const newResponses = [];

    leadEnquiryFormData?.questions.forEach((question) => {
      if (question.type === "CHOICE" && question.visibility === false) {
        newResponses.push({
          question_id: choiceQuestionId,
          type: "CHOICE",
          choice: choiceQuestion.choices[0]._id,
        });
      } else if (question.type !== "CHOICE" && question.visibility === false) {
        newResponses.push({
          question_id: question._id,
          type: "TEXT",
          text: "",
        });
      }
    });
    const existingChoiceResponse = responses.find(
      (response) =>
        response.question_id === choiceQuestionId && response.type === "CHOICE"
    );

    if (!existingChoiceResponse) {
      newResponses.push({
        question_id: choiceQuestionId,
        type: "CHOICE",
        choice: choiceQuestion.choices[0]?._id,
      });
    }

    const combinedResponses = [...responses, ...newResponses];

    return combinedResponses;
  }

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    if (type === "text") {
      setSubmissionConfirmationData((prevResponses) => ({
        ...prevResponses,
        name: value,
      }));
    }

    if (type === "tel") {
      setSubmissionConfirmationData((prevResponses) => ({
        ...prevResponses,
        mobileNumber: value,
      }));
    }

    if (type === "email") {
      setSubmissionConfirmationData((prevResponses) => ({
        ...prevResponses,
        email: value,
      }));
    }

    setResponses((prevResponses) => {
      const existingIndex = prevResponses.findIndex(
        (res) => res.question_id === name
      );

      const mobileNumberQuestion =
        leadEnquiryFormData &&
        leadEnquiryFormData.questions.find(
          (question) => question.type === "NUMBER"
        );

      const mobileNumberId = mobileNumberQuestion
        ? mobileNumberQuestion._id
        : null;

      const updatedValue =
        name === mobileNumberId ? `${countryCode}${value}` : value;

      if (existingIndex !== -1) {
        const updatedResponses = [...prevResponses];
        updatedResponses[existingIndex] = {
          question_id: name,
          type: name === choiceQuestionId ? "CHOICE" : "TEXT",
          text: type === "tel" ? updatedValue : value,
          ...(name === choiceQuestionId && {
            choice: value,
          }),
        };
        return updatedResponses;
      } else {
        if (type === "tel") {
          return [
            ...prevResponses,
            {
              question_id: name,
              type: "TEXT",
              text: updatedValue,
            },
          ];
        } else {
          return [
            ...prevResponses,
            {
              question_id: name,
              type: name === choiceQuestionId ? "CHOICE" : "TEXT",
              text: value,
              ...(name === choiceQuestionId && {
                choice: value,
              }),
            },
          ];
        }
      }
    });
  };

  function handleSubmit(event) {
    const formId = leadEnquiryFormData._id;
    event.preventDefault();

    const formResponses = responseForAllQuestions();

    postFormResponses(
      formId,
      formResponses,
      (successData) => {
        setResponses([]);

        setCountryCode("+91");

        const inputs = formRef.current.querySelectorAll(
          "input, textarea, select"
        );
        inputs.forEach((input) => {
          input.value = "";
        });

        submisssionConfirmationRef.current.showModal();

        console.log(successData);
      },
      (errorMessage) => {
        setSubmissionError(true);
        console.error("Form submission failed:", errorMessage);
      }
    );
  }

  return (
    <div className={styles.query} id="query">
      <div className={styles.details}>
        <h1 style={{ color: themeColor }}>Ask your queries</h1>
        <div className={styles.description}>
          <p>
            Please use this enquiry form to share your contact details and let
            us know how we can assist you with any questions or information you
            need.
          </p>
        </div>
      </div>
      <form ref={formRef} onSubmit={handleSubmit}>
        {leadEnquiryFormData &&
          leadEnquiryFormData.questions.map((question, index) => {
            return (
              question.visibility && (
                <div key={index}>
                  {question.type === "NUMBER" ? (
                    <div className={styles.number}>
                      <div className={styles.countryCode}>
                        <Select
                          value={countryCode}
                          className={styles.countryCodeSelect}
                          sx={{
                            fontFamily: "inherit",
                            fontSize: "inherit",
                            typography: "inherit",
                          }}
                          displayEmpty
                          onChange={handleCountryCodeChange}
                          MenuProps={{
                            container: formRef.current,
                          }}
                        >
                          <MenuItem
                            className={styles.menuItems}
                            sx={{ fontFamily: "inherit", fontSize: "inherit" }}
                            value="+91"
                          >
                            +91
                          </MenuItem>
                          <MenuItem
                            className={styles.menuItems}
                            sx={{ fontFamily: "inherit" }}
                            value="+1"
                          >
                            +1
                          </MenuItem>
                          <MenuItem
                            className={styles.menuItems}
                            sx={{ fontFamily: "inherit" }}
                            value="+66"
                          >
                            +66
                          </MenuItem>
                          <MenuItem
                            className={styles.menuItems}
                            sx={{ fontFamily: "inherit" }}
                            value="+44"
                          >
                            +44
                          </MenuItem>
                        </Select>
                      </div>
                      <div className={styles.mobileNumber}>
                        <input
                          name={question._id}
                          placeholder={`${question.title}${
                            question.is_required ? "*" : ""
                          }`}
                          required={question.is_required}
                          minLength="10"
                          pattern="[0-9]*"
                          maxLength={question.max_value || undefined}
                          onChange={handleChange}
                          type="tel"
                        />
                      </div>
                    </div>
                  ) : question.type === "TEXT" && question.max_value > 100 ? (
                    <textarea
                      name={question._id}
                      type={question.type.toLowerCase()}
                      placeholder={`${question.title}${
                        question.is_required ? "*" : ""
                      }`}
                      onChange={handleChange}
                      required={question.is_required}
                      maxLength={question.max_value || undefined}
                    />
                  ) : question.type === "CHOICE" ? (
                    <Select
                      defaultValue={question.choices[0]._id}
                      name={question._id}
                      className={styles.selectField}
                      onChange={handleChange}
                      type={question.type.toLowerCase()}
                      sx={{ fontFamily: "inherit" }}
                      MenuProps={{
                        container: formRef.current,
                      }}
                    >
                      {question.choices.map((choice) => (
                        <MenuItem
                          className={styles.menuItems}
                          key={choice._id}
                          name={choice._id}
                          sx={{ fontFamily: "inherit" }}
                          value={choice._id}
                        >
                          {choice.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <input
                      name={question._id}
                      type={question.type.toLowerCase()}
                      placeholder={`${question.title}${
                        question.is_required ? "*" : ""
                      }`}
                      onChange={handleChange}
                      required={question.is_required}
                      maxLength={question.max_value || undefined}
                    />
                  )}
                </div>
              )
            );
          })}
        <div className={styles.button}>
          <button
            style={{ backgroundColor: themeColor }}
            className={styles.submit}
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>{" "}
      <div className={styles.contactSales}>
        <div className={styles.title}>Contact Sales</div>
        {salesPerson.mobileNumber && (
          <div className={styles.salesDetails}>
            <FaPhoneAlt />{" "}
            <span className={styles.details}>{salesPerson.mobileNumber}</span>
          </div>
        )}

        {salesPerson.email && (
          <div className={styles.salesDetails}>
            <IoMdMail />{" "}
            <span className={styles.details}>{salesPerson.email}</span>
          </div>
        )}

        {salesPerson.whatsappNumber && (
          <div className={styles.salesDetails}>
            <IoLogoWhatsapp />{" "}
            <span className={styles.details}>{salesPerson.whatsappNumber}</span>
          </div>
        )}
      </div>
      <Snackbar
        open={submissionError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setSubmissionError(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error submitting form! Please try again.
        </Alert>
      </Snackbar>
      {submissionConfirmationData && (
        <SubmissionConfirmationPopup
          title={"Successfully Submitted"}
          description={
            "Thank you for reaching out! We've received your enquiry and our team will get back to you shortly."
          }
          identifiers={["Name", "Mobile", "Email"]}
          details={[
            submissionConfirmationData.name,
            submissionConfirmationData.mobileNumber,
            submissionConfirmationData.email,
          ]}
          okButton
          onClose={() =>
            setSubmissionConfirmationData({
              name: "",
              mobileNumber: "",
              email: "",
            })
          }
          ref={submisssionConfirmationRef}
        />
      )}
      <Snackbar
        open={submissionError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setSubmissionError(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error submitting form! Please try again.
        </Alert>
      </Snackbar>
    </div>
  );
}
