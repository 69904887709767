import { useState, useEffect } from "react";
import { useWindowWidth } from "../../../shared/helper";

import FullScreenModal from "./fullscreen-modal/FullscreenModal";
import ShowAll from "./helper/ShowAll";
import { getCustomerById } from "../../services/profile-page-api";
import Batches, { BatchCard } from "./batches/Batches";

export default function BatchesWrapperCard() {
  const { isMobile } = useWindowWidth();
  const [showModal, setShowModal] = useState(false);

  const handleShowAll = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const [batchesList, setBatchesList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch the batches data from API
    setLoading(true);
    getCustomerById(
      (response) => {
        setBatchesList(response.customer.batches);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  // const recentBatch = batchesList.slice(-1)[0];

  return (
    <div className="batches-wrapper-card">
      <div className="title-show-all">
        <div className="card-title-bold">Batches</div>
        {isMobile && <ShowAll handleClick={handleShowAll} />}
      </div>
      {batchesList.map((batchlist, index) => {
        return (
          <li key={`batch-${index}`}>
            <BatchCard
              imageUrl={batchlist.image_url}
              title={batchlist.general_details.title}
              desc={batchlist.general_details.description}
              startTime={batchlist.schedule.time}
            />
          </li>
        );
      })}
      {showModal && (
        <FullScreenModal
          onClose={handleClose}
          title={"Batches"}
          child={<Batches />}
        />
      )}
    </div>
  );
}
