import styles from "./Invoices.module.css";
import { useEffect, useState, useRef } from "react";
import { getInvoicesByCustomer } from "../../../services/profile-page-api";
import { formatDateTime } from "../../../../shared/helper";
import CircularProgress from "@mui/material/CircularProgress";
import PaymentsModal from "../payment-modal/PaymentsModal";
import { getCustomerById } from "../../../services/profile-page-api";

export default function Invoices({ isRecent, formId }) {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [memberInfo, setMemberInfo] = useState();

  useEffect(() => {
    getCustomerById(
      (response) => {
        setMemberInfo(response.customer);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    // Fetch the invoices data from API
    getInvoicesByCustomer(
      (response) => {
        setInvoices(isRecent ? response.invoices.slice(-3) : response.invoices);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, [isRecent]);

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <CircularProgress variant="soft" thickness={4} />
        </div>
      ) : (
        <div
          className={`${styles.invoicesList} ${isRecent ? styles.recent : ""}`}
        >
          <div className={styles.headerRow}>
            <TableHeader text="Invoice" />
            <TableHeader text="Date" />
            <TableHeader text="Amount" />
            <TableHeader text="Mode of payment" />
            <TableHeader text="Download / Pay" />
          </div>
          {invoices.map((invoice, index) => (
            <div className={styles.dataRow} key={index}>
              <TableData data={invoice.invoice_number} />
              <TableData data={formatDateTime(invoice.date)} />
              <TableData data={`₹ ${invoice.total_amount}`} />
              <TableData data={invoice.mode_of_payment} />
              <TableData
                data="payment"
                formId={formId}
                paymentStatus={invoice.payment_status}
                invoice={invoice}
                name={memberInfo?.name}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
}

function TableHeader({ text }) {
  return <div className={styles.tableHeader}>{text}</div>;
}

function TableData({ data, paymentStatus, formId, invoice, name }) {
  return (
    <div className={styles.tableData}>
      {data !== "payment" ? (
        data
      ) : (
        <Payment
          formId={formId}
          paymentStatus={paymentStatus}
          invoice={invoice}
          name={name}
        />
      )}
    </div>
  );
}

function Payment({ paymentStatus, formId, invoice, name }) {
  const payNowModal = useRef();
  return (
    <div className={styles.payment}>
      {paymentStatus === "DUE" ? (
        <button
          onClick={() => payNowModal.current && payNowModal.current.showModal()}
          className={styles.payNow}
        >
          Pay Now
        </button>
      ) : (
        <button className={styles.downloadReceipt}>Download Receipt</button>
      )}
      {formId && paymentStatus === "DUE" ? (
        <PaymentsModal
          ref={payNowModal}
          formId={formId}
          invoice={invoice}
          name={name}
        />
      ) : (
        ""
      )}
    </div>
  );
}
