import { MdCloudOff } from "react-icons/md";
import styles from "./PageNotFound.module.css";

export default function PageNotFound() {
  return (
    <div className={styles.page}>
      <div className={styles.notFoundIcon}>
        <MdCloudOff />
      </div>
      <div className={styles.title}>OOPS!</div>
      <div className={styles.desc}>
        We can't find the page that you are looking for <span>:(</span>
      </div>
    </div>
  );
}
