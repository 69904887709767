import React, { createContext, useContext } from "react";
import getLandingPageData from "../services/helper/get_landing_page_data";

const ColorThemeContext = createContext();

export const ColorThemeProvider = ({ children }) => {
  const landingPageData = getLandingPageData(window.pageData);
  const { theme } = landingPageData;

  const themeColor = theme.color;

  return (
    <ColorThemeContext.Provider value={{ themeColor }}>
      {children}
    </ColorThemeContext.Provider>
  );
};

export const useColorTheme = () => useContext(ColorThemeContext);
