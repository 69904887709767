import PaymentsModal from "../../payment-modal/PaymentsModal";
import "./InvoiceCard.css";
import { useRef, useEffect, useState } from "react";
import { formatDateTime, formatTime } from "../../../../../shared/helper";
import {
  getInvoicesByCustomer,
  getCustomerById,
} from "../../../../services/profile-page-api";
import { CircularProgress } from "@mui/material";

export default function InvoiceCard({ formId, isRecent }) {
  const payNowModal = useRef(null);
  const [invoices, setInvoices] = useState();
  const [loading, setLoading] = useState(true);
  const [memberInfo, setMemberInfo] = useState();

  function handlePayNowBtnClick() {
    if (payNowModal.current) {
      payNowModal.current.showModal();
    }
  }

  useEffect(() => {
    getInvoicesByCustomer(
      (response) => {
        setInvoices(isRecent ? response.invoices.slice(-3) : response.invoices);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        console.log(error);
      }
    );
  }, [isRecent]);

  useEffect(() => {
    getCustomerById(
      (response) => {
        setMemberInfo(response.customer);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <>
      {loading ? (
        <div className="loader-wrapper">
          <CircularProgress variant="soft" thickness={4} />
        </div>
      ) : (
        invoices &&
        memberInfo &&
        invoices.map((invoice, index) => (
          <div
            key={index}
            className={`dashboard-invoice-card ${isRecent ? "recent" : ""}`}
          >
            <div className="name-inv-no-row">
              <div className="name">{memberInfo?.name}</div>
              <div className="inv-no">Inv. No.</div>
            </div>
            <div className="date-invoice-no-row">
              <div className="date">{formatDateTime(invoice.date)}</div>
              <div className="invoice-no">{invoice.invoice_number}</div>
            </div>
            <div className="payment-info-row">
              <div className="amount-mop-row">
                <div className="amount">₹{invoice.total_amount}</div>
                {invoice.mode_of_payment && (
                  <div className="mop">{invoice.mode_of_payment}</div>
                )}
              </div>
              {invoice.payment_status === "PAID" && (
                <button className={`payment-button ${invoice.payment_status}`}>
                  Download Receipt
                </button>
              )}
              {invoice.payment_status === "DUE" && (
                <button
                  className={`payment-button ${invoice.payment_status}`}
                  onClick={handlePayNowBtnClick}
                >
                  Pay now
                </button>
              )}
              {formId ? (
                <PaymentsModal
                  ref={payNowModal}
                  formId={formId}
                  invoice={invoice}
                  name={memberInfo.name}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        ))
      )}
    </>
  );
}
