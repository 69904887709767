import { forwardRef, useState, useRef, useEffect } from "react";
import styles from "./PaymentsModal.module.css";
import QRCode from "../../../assets/QRcode.png";
import { IoMdClose } from "react-icons/io";
import SubmissionConfirmationPopup from "../submission-confirmation-popup/SubmissionConfirmationPopup";
import { postFormResponses } from "../../../../beta-home/services/network/post-form-response-api";
import { getFormByFormId } from "../../../../beta-home/services/network/get-form-by-formId";

import { Snackbar, Alert } from "@mui/material";

const PaymentsModal = forwardRef(({ formId, invoice, name }, ref) => {
  const [file, setFile] = useState(null);

  const fileInputRef = useRef(null);

  const formRef = useRef();

  function handleUploadClick() {
    fileInputRef.current.click();
  }
  function handleCancel() {
    fileInputRef.current.value = null;
    setFile(null);
  }

  const submisssionConfirmationRef = useRef(null);

  const [formData, setFormData] = useState();
  const [loadingState, setLoading] = useState();
  const [submissionError, setSubmissionError] = useState(false);

  useEffect(() => {
    getFormByFormId(
      (response) => {
        setFormData(response);
      },
      (error) => {
        console.error(error);
      },
      formId
    );
  }, [formId]);

  const paymentFormData = formData && formData;

  const [responses, setResponses] = useState([]);

  const handleChange = (event) => {
    const { id, value, type } = event.target;

    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }
    setResponses((prevResponses) => {
      const existingIndex = prevResponses.findIndex(
        (res) => res.question_id === id
      );
      const payment =
        paymentFormData &&
        paymentFormData.questions.find(
          (question) => question.type === "FILE_UPLOAD"
        );

      const paymentId = payment && payment._id;

      if (existingIndex !== -1) {
        const updatedResponses = [...prevResponses];
        updatedResponses[existingIndex] = {
          question_id: id,
          type: id === paymentId ? "FILE_UPLOAD" : type.toUpperCase(),
          text: value,
          ...(id === paymentId && {
            fileUrl: file && URL.createObjectURL(file),
          }),
        };
        return updatedResponses;
      } else {
        return [
          ...prevResponses,
          {
            question_id: id,
            type: id === paymentId ? "FILE_UPLOAD" : type.toUpperCase(),
            text: value,
            ...(id === paymentId && {
              fileUrl: file && URL.createObjectURL(file),
            }),
          },
        ];
      }
    });
  };

  function handleSubmit(event) {
    const formId = paymentFormData._id;
    event.preventDefault();

    postFormResponses(
      formId,
      responses,
      (successData) => {
        setResponses([]);
        const inputs = formRef.current.querySelectorAll(
          "input, textarea, select"
        );
        fileInputRef.current.value = null;
        setFile(null);
        inputs.forEach((input) => {
          input.value = "";
        });
        submisssionConfirmationRef.current.showModal();
      },
      (errorMessage) => {
        setSubmissionError(true);
        console.error("Form submission failed:", errorMessage);
      }
    );
  }

  return (
    <dialog className={styles.paymentModal} ref={ref} open={false}>
      <div className={styles.modalHeader}>
        <div className={styles.title}>Pay using the details given below</div>
        <div className={styles.closeButton} onClick={() => ref.current.close()}>
          <IoMdClose />
        </div>
      </div>
      <div className={styles.paymentDetailsCard}>
        <div className={styles.scan}>
          <div>Scan QR code</div>
          <div className={styles.scanImage}>
            <img src={QRCode} />
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.or}>OR</div>
        <div className={styles.bankDetails}>
          <div>Bank account details</div>
          <div className={styles.details}>
            <ul className={styles.labels}>
              <li>Account Number:</li>
              <li>IFSC Code:</li>
              <li>Account Holder:</li>
            </ul>
            <ul className={styles.values}>
              <li>32267379916</li>
              <li>SBIN0003226</li>
              <li>Peter</li>
            </ul>
          </div>
        </div>
      </div>
      <form ref={formRef} onSubmit={handleSubmit}>
        {paymentFormData &&
          paymentFormData.questions.map((question, index) => {
            return (
              question.visibility && (
                <div key={index}>
                  <input
                    placeholder="Enter payment reference number"
                    id={question._id}
                    name="reference number"
                    type="text"
                    className={styles.refNumber}
                    onChange={handleChange}
                    required
                    maxLength="20"
                  />
                  <div className={styles.upload}>
                    {file ? (
                      <div className={styles.previewImage}>
                        <IoMdClose
                          className={styles.close}
                          onClick={handleCancel}
                        />
                        <img
                          className={styles.image}
                          src={URL.createObjectURL(file)}
                          alt="Uploaded Screenshot"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <input
                      type="file"
                      ref={fileInputRef}
                      className={styles.uploadFiles}
                      id={question._id}
                      onChange={handleChange}
                      required
                      accept="image/jpeg, image/png"
                    />
                    <label
                      onClick={handleUploadClick}
                      className={styles.uploadButton}
                    >
                      {file ? "Change Image" : "Upload Image"}
                    </label>
                    <p>*Upload your payment screenshot (max size: 5 MB)</p>
                  </div>
                </div>
              )
            );
          })}
        <div className={styles.submit}>
          <button>SUBMIT</button>
        </div>
      </form>
      <SubmissionConfirmationPopup
        title={"Your Payment is under verification"}
        description={
          "After your payment is confirmed, you'll be able to join the batch"
        }
        identifiers={["Name", "Amount", "Payment Status"]}
        details={[
          name,
          // "Yoga for Beginners",
          "₹" + invoice.total_amount,
          "Pending Verification",
        ]}
        okButton
        ref={submisssionConfirmationRef}
      />
      <Snackbar
        open={submissionError}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={() => setSubmissionError(false)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          Error submitting form! Please try again.
        </Alert>
      </Snackbar>
    </dialog>
  );
});

export default PaymentsModal;
