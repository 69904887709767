import { forwardRef } from "react";
import styles from "./MenuModal.module.css";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";

import { MdClose } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import EnquiryModal from "../../enquiry-form/enquiry-form-modal/EnquiryModal";
import { useColorTheme } from "../../../ColorThemeProvider";

const MenuModal = forwardRef(function MenuModal(
  {
    servicesScroll,
    galleryScroll,
    testimonialScroll,
    batchesScroll,
    batchesPage,
    formId,
  },
  ref
) {
  const showEnquiryModal = useRef();

  const navigate = useNavigate();

  function handleEnquiryClick() {
    showEnquiryModal.current.showModal();
  }

  function handleEnquiryModalClose() {
    showEnquiryModal.current.close();
  }

  const { themeColor } = useColorTheme();

  return (
    <dialog className={styles.menuModalDialog} ref={ref}>
      <div className={styles.closeButton}>
        <button className={styles.close} onClick={() => ref.current.close()}>
          <MdClose />
        </button>
      </div>
      <ul className={styles.menuModalList}>
        <li
          onClick={
            !batchesPage ? () => ref.current.close() : () => navigate("/")
          }
          className={styles.menuItem}
        >
          Home
        </li>
        <li
          onClick={() => {
            ref.current.close();
            navigate("/#services");
          }}
          className={styles.menuItem}
        >
          Services
        </li>
        <li
          onClick={() => {
            ref.current.close();
            navigate("/#whychooseus");
          }}
          className={styles.menuItem}
        >
          Why Choose Us
        </li>
        <li
          onClick={
            batchesPage ? () => ref.current.close() : () => navigate("/batches")
          }
          className={styles.menuItem}
        >
          Batches
        </li>
        <li
          className={styles.menuItem}
          onClick={() => {
            ref.current.close();
            navigate("/#gallery");
          }}
        >
          Gallery
        </li>
        <li
          className={styles.menuItem}
          onClick={() => {
            ref.current.close();
            navigate("/#testimonials");
          }}
        >
          Testimonial
        </li>

        <li className={styles.actionButton}>
          <button
            style={{ backgroundColor: themeColor }}
            onClick={handleEnquiryClick}
          >
            Enquiry
          </button>
        </li>
        <EnquiryModal
          ref={showEnquiryModal}
          onClose={handleEnquiryModalClose}
          formId={formId}
        />
        <li className={styles.actionButton}>
          <button
            style={{ backgroundColor: themeColor }}
            onClick={() => navigate("/member")}
          >
            Sign in
          </button>
        </li>
        <li className={styles.call}>
          Call at{" "}
          <span>
            <FaPhoneAlt /> 9876543211
          </span>
        </li>
      </ul>
    </dialog>
  );
});

export default MenuModal;
