import { getMemberDataLocal, setMemberDataLocal } from "../../../shared/helper";
import GoogleLoginPage from "../../../shared/components/pages/google-login-page/GoogleLoginPage";
import { loginMember } from "../../services/profile-page-api";

export default function MemberGoogleLogin() {
  async function handleSuccessGoogleLogin(googleUserInfo) {
    console.log(googleUserInfo);

    const queryParams = new URLSearchParams(window.location.search);
    const subdomain = queryParams.get("sd");

    await loginMember(
      googleUserInfo.email,
      (serverMemberData) => {
        const encodedAuthData = setMemberDataLocal(
          JSON.stringify(serverMemberData)
        );

        console.log(getMemberDataLocal());
        console.log(googleUserInfo.email);
        console.log(encodedAuthData);
        window.location.href = `${window.location.protocol}//${subdomain}.${window.location.host}/member?auth=${encodedAuthData}`;
      },
      () => {
        console.log("Login failed");
      }
    );
  }
  return <GoogleLoginPage onSuccess={handleSuccessGoogleLogin} />;
}
