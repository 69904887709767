import axios from "axios";
import getLandingPageData from "../helper/get_landing_page_data";
import { BASE_URL } from "../../../shared/constants";

export async function postFormResponses(
  formId,
  responses,
  onSuccess,
  onFailure
) {
  const landingPageData = getLandingPageData(window.pageData);

  const { userdbId } = landingPageData;

  try {
    const requestBody = {
      form_id: formId,
      user_db_id: userdbId,
      responses: responses,
    };

    const response = await axios.post(`${BASE_URL}/response`, requestBody, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message
    );
    if (onFailure) {
      const errorMessage = error.response ? error.response.data : error.message;
      onFailure(errorMessage);
    }
  }
}
