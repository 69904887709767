import { MdNavigateNext } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import "./utils.css";

export function ShowAll() {
  return (
    <div id="show-all">
      <span>Show All</span>
      <MdOutlineArrowForwardIos />
    </div>
  );
}

export function ReadMore() {
  return (
    <div id="read-more">
      <span>Read more</span>
      <MdNavigateNext />
    </div>
  );
}
