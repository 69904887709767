import ContactUsModal from "../layouts/contact-us-modal/ContactUsModal";
import { useRef } from "react";
export default function ContactUsCard({ businessDetails }) {
  const contactUsRef = useRef(null);

  function handleClick() {
    contactUsRef.current.showModal();
  }

  return (
    <div className="contact-us-card">
      <div className="card-title-bold">Contact us</div>
      <span>
        If you have any questions or need assistance, feel free to reach out to
        us. We're here to help!
      </span>
      <button onClick={handleClick} className="get-support-btn">
        Get Support
      </button>
      <ContactUsModal businessDetails={businessDetails} ref={contactUsRef} />
    </div>
  );
}
