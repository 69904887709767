import { useState, useEffect } from "react";
import { useWindowWidth } from "../../../shared/helper";
import FullScreenModal from "../layouts/fullscreen-modal/FullscreenModal";
import InvoiceCard from "../layouts/mobile/invoice-card/InvoiceCard";
import InvoicesList from "../layouts/mobile/invoices-list/InvoicesList";
import ShowAll from "../layouts/helper/ShowAll";
import Invoices from "./invoices/Invoices";
import { getInvoiceByID } from "../../services/profile-page-api";

export default function InvoicesWrapperCard({ formId }) {
  const [showModal, setShowModal] = useState(false);
  const { isMobile } = useWindowWidth();

  const handleShowAll = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <div className="invoices-wrapper-card">
      <div className="title-show-all">
        <div className="card-title-bold">Payments</div>
        <ShowAll handleClick={handleShowAll} />
      </div>
      {isMobile ? (
        <InvoiceCard isRecent={true} formId={formId} />
      ) : (
        <Invoices isRecent={true} formId={formId} />
      )}

      {showModal && (
        <FullScreenModal
          onClose={handleClose}
          title={"Invoices"}
          child={
            isMobile ? (
              <InvoicesList formId={formId} />
            ) : (
              <Invoices formId={formId} />
            )
          }
        />
      )}
    </div>
  );
}
