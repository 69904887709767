import quote from "../../../assets/quote.svg";
import AllTestimonials from "../modals/All-Testimonials/AllTestimonials.jsx";
import { BiSolidQuoteAltLeft } from "react-icons/bi";

import styles from "./Testimonials.module.css";
import { forwardRef, useRef } from "react";
import { useColorTheme } from "../../ColorThemeProvider.jsx";

const Testimonials = forwardRef(function Testimonials(
  { testimonialsList },
  ref
) {
  const showAllTestimonialsRef = useRef(null);

  function handleAllTestimonialsOpen() {
    showAllTestimonialsRef.current.showModal();
  }

  function handleAllTestimonialsClose() {
    showAllTestimonialsRef.current.close();
  }

  return (
    <>
      <AllTestimonials
        ref={showAllTestimonialsRef}
        onClose={handleAllTestimonialsClose}
        testimonialsList={testimonialsList}
      />
      <div ref={ref} id="testimonials" className={styles.testimonials}>
        <div className={styles.titleHeader}>
          <h2>Testimonials</h2>
          {/* <div
            className={testimonialsList.length > 4 ? "show-all" : "no-show-all"}
            onClick={handleAllTestimonialsOpen}
          >
            <ShowAll />
          </div> */}
        </div>
        <ul>
          {testimonialsList.map((item, index) => {
            return (
              <li key={`testimonial-${index}`}>
                <TestimonialCard
                  imageUrl={item.image_url || ""}
                  title={item.title || ""}
                  desc={item.description || ""}
                  name={item.name || ""}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
});

export default Testimonials;

export function TestimonialCard({ imageUrl, title, desc, name }) {
  const { themeColor } = useColorTheme();
  return (
    <div className={styles.testimonialCard}>
      <div className={styles.title}>
        <span className={styles.image}>
          <BiSolidQuoteAltLeft color={themeColor} />
        </span>
        {/* <img src={quote} alt="" /> */}
        <span className={styles.textBold}>{title}</span>
      </div>
      <span className={styles.desc}>{desc}</span>
      <div className={styles.studentInfo}>
        <img src={imageUrl} alt="" />
        <div className={styles.details}>
          <span className={styles.name}>{name}</span>
        </div>
      </div>
    </div>
  );
}
