import { Route, Routes } from "react-router-dom";
import MemberDashboard from "./member-dashboard/components/pages/dashboard/MemberDashboard";
import Home from "./yogawav-home/components/pages/Home";
import BetaHome from "./beta-home/components/pages/BetaHome";
import Invoice from "./invoices/components/pages/invoice/Invoice";
import MemberGoogleLogin from "./member-dashboard/components/pages/MemberGoogleLogin";
import { PAGE_TYPE } from "./shared/constants";
import BatchesPage from "./beta-home/components/pages/batches/batches-page/BatchesPage";
import PageNotFound from "./beta-home/components/layouts/pageNotFound/PageNotFound";

export default function App(props) {
  if (props.pageType === PAGE_TYPE.ALPHA_LANDING) {
    return (
      <Routes>
        <Route path="/" element={<BetaHome />} />
        <Route path="member" element={<MemberDashboard />} />
        <Route path="invoice" element={<Invoice />} />
        <Route path="batches" element={<BatchesPage />} />
        <Route path="/error" element={<PageNotFound />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="auth" element={<MemberGoogleLogin />} />
      </Routes>
    );
  }
}
