import InvoiceCard from "../invoice-card/InvoiceCard";
import "./InvoicesList.css";

export default function InvoicesList(formId) {
  return (
    <div className="mobile-dashboard-invoices-list">
      <ul>
        <li>
          <InvoiceCard formId={formId} />
        </li>
      </ul>
    </div>
  );
}
