import styles from "./ContactUsModal.module.css";
import { forwardRef } from "react";
import { IoMdClose } from "react-icons/io";

const ContactUsModal = forwardRef(function ContacUsModal(
  { businessDetails },
  ref
) {
  return (
    <dialog className={styles.contactUsModal} ref={ref}>
      <div className={styles.close}>
        <button
          className={styles.closeButton}
          onClick={() => ref.current.close()}
        >
          <IoMdClose />
        </button>
      </div>
      <div className={styles.title}>Contact Us</div>
      <div className={styles.desc}>Please use below details to contact us</div>
      <div className={styles.contactDetails}>
        <div className={styles.label}>Contact Number</div>
        <div className={styles.businessDetail}>
          <div className={styles.mobileNumber}>
            {businessDetails.mobileNumber}
          </div>
          <button
            className={styles.textBlue}
            onClick={() =>
              navigator.clipboard.writeText(businessDetails.mobileNumber)
            }
          >
            Copy
          </button>
        </div>
      </div>
      <div className={styles.contactDetails}>
        <div className={styles.label}>Mail Us</div>
        <div className={styles.businessDetail}>
          <div className={styles.email}>{businessDetails.email}</div>
          <button
            className={styles.textBlue}
            onClick={() => navigator.clipboard.writeText(businessDetails.email)}
          >
            {" "}
            Copy
          </button>
        </div>
      </div>
      <div className={`${styles.contactDetails} ${styles.addressDetail}`}>
        <div className={styles.address}>Address</div>
        <div className={styles.addressDetails}>
          <div> {businessDetails.address} </div>
        </div>
      </div>
    </dialog>
  );
});

export default ContactUsModal;
