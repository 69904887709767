import AllWhyUs from "../modals/WhyUs/All-Why-Us/AllWhyUs.jsx";
import WhyUsModal from "../modals/WhyUs/WhyUsModal/WhyUsModal.jsx";
// import { ReadMore, ShowAll } from "../../../../shared/components/utils/utils";
import { ReadMore } from "../../../../shared/components/utils/utils";

import styles from "./WhyUs.module.css";
import { useRef, useState } from "react";
import { useColorTheme } from "../../ColorThemeProvider.jsx";

export default function WhyUs({ whyUsList }) {
  const showAllWhyUsRef = useRef(null);
  const whyUsModalRef = useRef(null);

  const [selectedWhyUs, setSelectedWhyUs] = useState(null);

  function handleAllWhyUsOpen() {
    showAllWhyUsRef.current.showModal();
  }

  function handleAllWhyUsClose() {
    showAllWhyUsRef.current.close();
  }

  function handleWhyUsModalOpen(whyUs) {
    whyUsModalRef.current.showModal();
    setSelectedWhyUs(whyUs);
  }

  function handleWhyUsModalClose() {
    whyUsModalRef.current.close();
  }

  return (
    <>
      <AllWhyUs
        ref={showAllWhyUsRef}
        onClose={handleAllWhyUsClose}
        whyUsList={whyUsList}
      />
      <WhyUsModal
        ref={whyUsModalRef}
        onClose={handleWhyUsModalClose}
        whyUs={selectedWhyUs != null ? selectedWhyUs : {}}
      />

      <div className={styles.usp} id="whychooseus">
        <div className={styles.titleHeader}>
          <h2>Why Choose Us</h2>
        </div>
        <ul>
          {whyUsList.map((whyUs, index) => {
            return (
              <li
                key={`whyUs-${index}`}
                onClick={() => handleWhyUsModalOpen(whyUs)}
              >
                <WhyUsCard
                  imageUrl={whyUs.image_url || ""}
                  title={whyUs.title || ""}
                  desc={whyUs.description || ""}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export function WhyUsCard({ imageUrl, title, desc }) {
  const { themeColor } = useColorTheme();
  return (
    <div className={styles.uspCard}>
      <img src={imageUrl} alt="" />
      <span className={styles.title}>{title}</span>
      <span className={styles.desc}>{desc}</span>

      <span className={styles.readMore} style={{ color: themeColor }}>
        <ReadMore />
      </span>
    </div>
  );
}
