import whatsapp from "../../../assets/social/mobile/whatsapp.svg";
import facebook from "../../../assets/social/mobile/facebook.svg";
import instagram from "../../../assets/social/mobile/instagram.svg";
import gmail from "../../../assets/social/mobile/gmail.svg";
import twitter from "../../../assets/social/mobile/twitter.svg";
import share from "../../../assets/social/mobile/share.svg";

import "./InviteFriendsCard.css";

export default function InviteFriendsCard({
  socialLinks,
  businessDetails,
  sales,
}) {
  return (
    <div className="invite-friends-card">
      <div className="card-title-bold">Invite Friends</div>
      <div className="social-icons-row">
        <SocialIcon
          platform={whatsapp}
          socialLink={`https://wa.me/${sales.whatsappNumber}`}
        />
        <SocialIcon platform={facebook} socialLink={socialLinks.facebook} />
        <SocialIcon platform={instagram} socialLink={socialLinks.instagram} />
        <SocialIcon
          platform={gmail}
          socialLink={`mailto:${businessDetails.email}`}
        />
        <SocialIcon platform={twitter} socialLink={socialLinks.twitter} />
        <SocialIcon platform={share} socialLink={socialLinks.share} />
      </div>
    </div>
  );
}

function SocialIcon({ platform, socialLink }) {
  function openLink(url) {
    if (url) {
      window.open(url, "_blank", "noopener, noreferrer");
    }
  }
  return (
    <div className="social-icon" onClick={() => openLink(socialLink)}>
      <img src={platform} alt="" />
    </div>
  );
}
