import AllServices from "../modals/Services/All-Services/AllServices.jsx";
import ServiceModal from "../modals/Services/Service-Modal/ServiceModal.jsx";
import { ShowAll, ReadMore } from "../../../../shared/components/utils/utils";

import styles from "./Services.module.css";
import { forwardRef, useRef, useState } from "react";
import { useColorTheme } from "../../ColorThemeProvider.jsx";

const Services = forwardRef(function Services({ servicesList }, ref) {
  const showAllServicesRef = useRef(null);
  const serviceModalRef = useRef(null);

  const [selectedService, setSelectedService] = useState(null);

  function handleAllServicesOpen() {
    showAllServicesRef.current.showModal();
  }

  function handleAllServicesClose() {
    showAllServicesRef.current.close();
  }

  function handleServiceModalOpen(service) {
    serviceModalRef.current.showModal();
    setSelectedService(service);
  }

  function handleServiceModalClose() {
    serviceModalRef.current.close();
  }

  return (
    <>
      <AllServices
        ref={showAllServicesRef}
        onClose={handleAllServicesClose}
        servicesList={servicesList}
      />

      <ServiceModal
        ref={serviceModalRef}
        onClose={handleServiceModalClose}
        service={selectedService != null ? selectedService : {}}
      />

      <div ref={ref} className={styles.services} id="services">
        <div className={styles.titleHeader}>
          <h2>Our Services & Benefits</h2>
          {/* <div
            className={servicesList.length > 6 ? "show-all" : "no-show-all"}
            onClick={handleAllServicesOpen}
          >
            <ShowAll />
          </div> */}
        </div>

        <ul>
          {servicesList.map((service, index) => {
            return (
              index < 6 && (
                <li
                  key={`service-${index}`}
                  onClick={() => handleServiceModalOpen(service)}
                >
                  <ServiceCard
                    imageUrl={service.image_url || ""}
                    title={service.title || ""}
                    desc={service.description || ""}
                  />
                </li>
              )
            );
          })}
        </ul>
      </div>
    </>
  );
});

export function ServiceCard({ imageUrl, title, desc }) {
  const { themeColor } = useColorTheme();

  return (
    <div className={styles.serviceCard}>
      <img src={imageUrl} alt="" />
      <div className={styles.content}>
        <div>
          <span className={styles.title}>{title}</span>
          <span className={styles.desc}>{desc}</span>
        </div>
        <div>
          <span style={{ color: themeColor }} className={styles.readMore}>
            <ReadMore />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Services;
