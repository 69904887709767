import styles from "./BatchesModal.module.css";
import { forwardRef } from "react";
import { FiArrowLeft } from "react-icons/fi";

import { useState, useRef, useEffect } from "react";
import { MdCheck } from "react-icons/md";
import { useColorTheme } from "../../../ColorThemeProvider";
import { postFormResponses } from "../../../../services/network/post-form-response-api";
import SubmissionConfirmationPopup from "../../../../../member-dashboard/components/layouts/submission-confirmation-popup/SubmissionConfirmationPopup";
import { getFormByFormId } from "../../../../services/network/get-form-by-formId";

import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Alert,
} from "@mui/material";

import QRCode from "../../../../assets/QRcode.png";
import { IoMdClose } from "react-icons/io";

const BatchesModal = forwardRef(function BatchesModal(
  { batchData, formId },
  ref
) {
  const submissionConfirmationRef = useRef();
  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState();
  const [submissionError, setSubmissionError] = useState(false);

  const [file, setFile] = useState(null);

  const fileInputRef = useRef(null);

  function handleUploadClick() {
    fileInputRef.current.click();
  }

  function handleCancel() {
    fileInputRef.current.value = null;
    setFile(null);
  }

  const formRef = useRef();

  useEffect(() => {
    setLoading(true);
    getFormByFormId(
      (response) => {
        setFormData(response);
        setLoading(false);
      },
      (error) => {
        console.error(error);
        setLoading(false);
      },
      formId
    );
  }, [formId]);

  const memberFormData = formData && formData;

  const [responses, setResponses] = useState([]);

  const handleChange = (event) => {
    const { name, value, type } = event.target;

    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
    }

    const nameQuestion =
      memberFormData &&
      memberFormData.questions.find(
        (question) => question.type === "TEXT" && question.title === "Name"
      );

    const nameQuestionId = nameQuestion && nameQuestion._id;

    if (name === nameQuestionId) {
      setSubmissionConfirmationData(value);
    }

    setResponses((prevResponses) => {
      const existingIndex = prevResponses.findIndex(
        (res) => res.question_id === name
      );

      const payment =
        memberFormData &&
        memberFormData.questions.find(
          (question) => question.type === "FILE_UPLOAD"
        );

      const paymentId = payment && payment._id;

      const gender =
        memberFormData &&
        memberFormData.questions.find((question) => question.type === "CHOICE");

      const genderId = gender && gender._id;

      if (existingIndex !== -1) {
        const updatedResponses = [...prevResponses];

        updatedResponses[existingIndex] = {
          question_id: name,
          type:
            name === paymentId
              ? "FILE_UPLOAD"
              : name === genderId
              ? "CHOICE"
              : "TEXT",
          text: value,
          ...(name === paymentId && {
            fileUrl: file && URL.createObjectURL(file),
          }),
          ...(name === genderId && {
            choice: value,
          }),
        };
        return updatedResponses;
      } else {
        return [
          ...prevResponses,
          {
            question_id: name,
            type:
              name === paymentId
                ? "FILE_UPLOAD"
                : name === genderId
                ? "CHOICE"
                : "TEXT",
            text: value,
            ...(name === paymentId && {
              file_url: file && URL.createObjectURL(file),
            }),
            ...(name === genderId && {
              choice: value,
            }),
          },
        ];
      }
    });
  };

  const [submissionConfirmationData, setSubmissionConfirmationData] =
    useState();

  function handleSubmit(event) {
    const formId = memberFormData._id;
    event.preventDefault();

    postFormResponses(
      formId,
      responses,
      (successData) => {
        setResponses([]);

        const inputs = formRef.current.querySelectorAll(
          "input, textarea, select"
        );

        inputs.forEach((input) => {
          input.value = "";
        });

        setFile(null);

        submissionConfirmationRef.current.showModal();
      },
      (errorMessage) => {
        setSubmissionError(true);
        console.error("Form submission failed:", errorMessage);
      }
    );
  }

  const { themeColor } = useColorTheme();

  return (
    <>
      <dialog className={styles.batchesModal} ref={ref}>
        <header className={styles.batchesModalHeader}>
          <div className={styles.arrow}>
            <button
              className={styles.backBtn}
              onClick={() => ref.current.close()}
            >
              <FiArrowLeft />
            </button>
          </div>
          <div className={styles.batchImage}>
            <img src={batchData.image_url} />
          </div>
          <div className={styles.batchDetails}>
            <div className={styles.batchTitle}>
              {batchData.general_details.title}
            </div>
            <div className={styles.batchPrice}>
              Rs.{batchData.pricing.payable_amount}
            </div>
          </div>
        </header>
        <form
          className={styles.batchForm}
          onSubmit={handleSubmit}
          ref={formRef}
        >
          {memberFormData &&
            memberFormData.questions.map((question) => {
              return (
                question.visibility && (
                  <div key={question._id}>
                    {question.type === "CHOICE" ? (
                      <div className={styles.batchInput} id="form">
                        <FormControl
                          fullWidth
                          className={styles.batchInput}
                          sx={{
                            fontFamily: "inherit",
                            backgroundColor: "white",
                          }}
                        >
                          {" "}
                          <InputLabel
                            id={`label-${question._id}`}
                            sx={{
                              fontFamily: "inherit",
                              backgroundColor: "white",
                              paddingInline: "4px",
                            }}
                          >
                            {question.title}
                          </InputLabel>
                          <Select
                            MenuProps={{
                              container: formRef.current,
                            }}
                            name={question._id}
                            labelId={`label-${question._id}`}
                            value={
                              responses.find(
                                (res) => res.question_id === question._id
                              )?.text || ""
                            }
                            onChange={handleChange}
                            displayEmpty
                            sx={{ fontFamily: "inherit" }}
                          >
                            {question.choices.map((choice) => (
                              <MenuItem
                                key={choice._id}
                                value={choice._id}
                                sx={{ fontFamily: "inherit" }}
                              >
                                {choice.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    ) : question.type === "FILE_UPLOAD" ? (
                      <div className={styles.paymentModal}>
                        <div className={styles.modalHeader}>
                          <div className={styles.title}>Payment</div>
                        </div>
                        <div className={styles.paymentDetailsCard}>
                          <div className={styles.scan}>
                            <div>Scan QR code</div>
                            <div className={styles.scanImage}>
                              <img src={QRCode} />
                            </div>
                          </div>
                          <div className={styles.line}></div>
                          <div className={styles.or}>OR</div>
                          <div className={styles.bankDetails}>
                            <div>Bank account details</div>
                            <div className={styles.details}>
                              <ul className={styles.labels}>
                                <li>Account Number:</li>
                                <li>IFSC Code:</li>
                                <li>Account Holder:</li>
                              </ul>
                              <ul className={styles.values}>
                                <li>32267379916</li>
                                <li>SBIN0003226</li>
                                <li>Peter</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <input
                          placeholder="Enter payment reference number"
                          type="text"
                          maxLength="20"
                          className={styles.refNumber}
                          onChange={handleChange}
                          name={question._id}
                          required
                        />
                        <div className={styles.upload}>
                          {file ? (
                            <div className={styles.previewImage}>
                              <IoMdClose
                                className={styles.close}
                                onClick={handleCancel}
                              />
                              <img
                                className={styles.image}
                                src={URL.createObjectURL(file)}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <input
                            type="file"
                            ref={fileInputRef}
                            className={styles.uploadFiles}
                            onChange={handleChange}
                            name={question._id}
                            accept="image/jpeg, image/png"
                            required
                          />
                          <label
                            style={{
                              color: themeColor,
                              borderColor: themeColor,
                            }}
                            onClick={handleUploadClick}
                            className={styles.uploadButton}
                            htmlFor={question._id}
                          >
                            {file ? "Change Image" : "Upload Image"}
                          </label>
                          <p>
                            *Upload your payment screenshot (max size: 5 MB)
                          </p>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.batchInput}>
                        <TextField
                          label={question.title}
                          type={
                            question.type === "NUMBER"
                              ? "tel"
                              : question.type.toLowerCase()
                          }
                          sx={{ fontFamily: "inherit" }}
                          maxLength={question.max_value || undefined}
                          onChange={handleChange}
                          className={styles.input}
                          fullWidth
                          name={question._id}
                          required={question.is_required}
                          value={
                            question.title === "Batch"
                              ? batchData.general_details.title
                              : responses.find(
                                  (res) => res.question_id === question._id
                                )?.text || ""
                          }
                          disabled={question.title === "Batch"}
                          minLength={
                            question.type === "NUMBER" ? "10" : undefined
                          }
                          pattern={
                            question.type === "NUMBER" ? "[0-9]*" : undefined
                          }
                          InputProps={{
                            style: { fontFamily: "inherit" },
                          }}
                          InputLabelProps={{
                            style: { fontFamily: "inherit" },
                          }}
                        />
                      </div>
                    )}
                  </div>
                )
              );
            })}

          <div className={styles.button}>
            <button
              style={{ backgroundColor: themeColor }}
              className={styles.submit}
              type="submit"
            >
              <div style={{ color: themeColor }} className={styles.checkMark}>
                <MdCheck />
              </div>
              Submit
            </button>
          </div>
          <SubmissionConfirmationPopup
            title={"Your Batch has been Booked!"}
            description={
              "After your payment is confirmed, you'll be able to join the batch"
            }
            identifiers={["Name", "Batch", "Amount", "Payment Status"]}
            details={[
              submissionConfirmationData,
              batchData.general_details.title,
              "Rs." + batchData.pricing.payable_amount,
              "Pending Verification",
            ]}
            closeButton
            onClose={() => setSubmissionConfirmationData("")}
            ref={submissionConfirmationRef}
          />
        </form>
        <Snackbar
          open={submissionError}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={() => setSubmissionError(false)}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            Error submitting form! Please try again.
          </Alert>
        </Snackbar>
      </dialog>
    </>
  );
});

export default BatchesModal;
