import styles from "./PreviewImage.module.css";
import whatsapp from "../../../assets/whatsapp.svg";

export default function PreviewImage({ welcomeSectionDetails, sales }) {
  const salesWhatsappNumber = sales.whatsappNumber;
  const handleClick = () => {
    // Open WhatsApp link in a new tab
    window.open(
      `https://wa.me/${salesWhatsappNumber}`,
      "_blank",
      "noopener, noreferrer"
    );
  };

  return (
    <div
      className={styles.previewImage}
      style={{
        backgroundImage: `url(${welcomeSectionDetails.image})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className={styles.textOverlay}>
        <div className={styles.text}>
          <span className={styles.title}>{welcomeSectionDetails.title}</span>
          <span className={styles.desc}>{welcomeSectionDetails.aboutUs}</span>
        </div>
      </div>
      {salesWhatsappNumber && (
        <div className={styles.whatsapp} onClick={handleClick}>
          <img src={whatsapp} alt="" />
        </div>
      )}
    </div>
  );
}
